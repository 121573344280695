<template>
    <div>
        <b-modal id="modal-scrollable" size="md" ref="my-modal" hide-footer title="Add/Edit Video Gallery">
            <div class="row">
                <div class="col-12 form-group">
                    <!-- <label for="">Title</label> -->
                    <input type="text" class="form-control border border-dark" v-model="video.title"
                           placeholder="Title"/>
                    <span class="text-danger" v-if="$v.video.title.$error">Title is required</span>
                </div>


                <label for class="float-left pl-5 pt-5">Tags</label>

                <div class="col-12 form-group">
                    <b-form-tags input-id="tags-basic" class="border border-dark" v-model="video.tags"></b-form-tags>
                </div>
                <div class="col-12 form-group">
                    <label for class="float-left">Video Url</label>
                    <input type="text" class="form-control border border-dark" v-model="video.video_url"
                           placeholder="Title"/>
                    <!-- <span class="text-danger" v-if="$v.page.content.$error">Content is required</span> -->
                </div>
                <div class="col-12 form-group">
                    <label for class="float-left">Descirption</label>
                    <textarea  class="form-control border border-dark" v-model="video.description"
                           placeholder="Description"/>
                    <!-- <span class="text-danger" v-if="$v.page.content.$error">Content is required</span> -->
                </div>
                <div class="col-12 form-group mt-5">
                    <b-form-checkbox
                            v-model="video.is_active"
                            name="check-button"
                            class
                            switch
                    >Status
                    </b-form-checkbox>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <v-btn
                        depressed
                        @click="hideModal"
                        class="text-gray btn btn-standard
                    ">
                        Cancel
                    </v-btn>
                </div>
                <div class="col-6">
                    <v-btn
                        depressed
                        :loading="isBusy"
                        @click="createOrUpdate"
                        class="text-white ml-2 btn btn-primary"
                        :disabled="sizeExceed"
                        block
                    >Save
                    </v-btn>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import VideoGalleryService from "@/core/services/videoGallery/VideoGalleryService";

    const videoGallery = new VideoGalleryService();
    export default {
        validations: {
            video: {
                title: {required}
            }
        },
        data() {
            return {
                edit: false,
                sizeExceed: false,
                isBusy: false,
                video: {
                    title: null,
                    video_url: null,
                    description: null,
                    is_active: true
                }
            };
        },
        methods: {
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.getVideo(id)
                }
                this.$refs["my-modal"].show();
            },
            hideModal() {
                this.$refs["my-modal"].hide();
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
            getVideo(id) {
                videoGallery.show(id).then(response => {
                    this.video = response.data.videoGallery
                    if (this.video.tags) {
                        this.video.tags = this.video.tags.split(',');
                    }
                })
            },
            generateFd() {
                // console.log(this.video)
                let fd = new FormData();
                for (let key in this.video) {
                    if (key == "is_active" && this.video["is_active"]) {
                        fd.append("is_active", this.video ? 1 : 0);
                    } else {
                        if (this.video[key] != null) {
                            fd.append(key, this.video[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {
                this.$v.video.$touch();
                if (this.$v.video.$error) {
                    setTimeout(() => {
                        this.$v.video.$reset();
                    }, 3000);
                } else {
                    this.video.tags = (this.video.tags && this.video.tags.length>0)?this.video.tags.join(","):null
                    let fd = this.video;
                    if (this.edit) {
                        this.updateAlbum(fd);
                    } else {
                        this.createAlbum(fd);
                    }
                }
            },
            updateAlbum(fd) {
                this.isBusy = true;
                videoGallery
                    .update(this.video.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            createAlbum(fd) {
                this.isBusy = true;
                videoGallery
                    .store(fd)
                    .then(response => {
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.video.$reset();
                this.edit = false;
                this.video = {
                    title: null,
                    is_active: null
                };
                this.hideModal();
                this.$emit("refresh_video");
            }
        }
    };
</script>
