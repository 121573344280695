<template>
    <v-app>
      <div class="row">
        <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4> Menu manager</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\   Menu manager
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                  <v-btn
                      @click="manageCategory()"
                      class="mt-1 btn btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add menu category
                  </v-btn>

                </div>
              </div>
            </div>
          </div>
            <div class="card-body">
              <div class="">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="px-3 wrap-column">Title</th>
                            <th class="px-3 wrap-column">Menu type</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-show="menus.length > 0" v-for="(menu, index) of menus" :key="menu.title">
                        <td scope="row">
                            <router-link :to="{ name: 'menu-manager-menu' , params: {id: menu.id}}"
                                        class="navi-link">{{menu.title }}
                            </router-link>&nbsp;&nbsp;
                            <i class="fas fa-circle ml-2"
                            :class="menu.is_active?'dot-active':'dot-inactive'"></i>
                        </td>

                        <td class="pt-3">
                            <span :class="menu.header?'badge badge-success':'badge badge-danger'">{{menu.header?'Header':'Footer'}}</span>
                        </td>
                        <td>
                            <b-dropdown
                                size="sm"
                                variant="link"
                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                no-caret
                                right
                                no-flip
                            >
                            <template v-slot:button-content>
                                <slot>
                                                                <span>
                                                                            <i class="flaticon-more-1"></i>
                                                                        </span></slot>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover ">


                                <b-dropdown-text tag="div" class="navi-item">
                                <a href="#" class="navi-link" @click="editMenu(menu)">
                                                                    <span class="navi-icon">
                                                                    <i class="flaticon-edit"></i>
                                                                    </span>
                                    <span class="navi-text"> Edit </span>
                                </a>
                                </b-dropdown-text>

                                <b-dropdown-text tag="div" class="navi-item">
                                <a href="#" class="navi-link"
                                    @click="deleteMenu(menu.id)">
                                                                    <span class="navi-icon">
                                                                    <i class="fas fa-trash"></i>
                                                                    </span>
                                    <span class="navi-text">  Delete</span>
                                </a>
                                </b-dropdown-text>

                            </div>
                            <!--end::Navigation-->
                            </b-dropdown>

                        </td>
                        </tr>
                        <tr v-show="menus.length==0">
                        <td colspan="3" class="text-center">
                            <strong>
                            No data available to display.</strong>
                        </td>
                        </tr>
                    </tbody>
                </table>
              </div>
<!--                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        &lt;!&ndash;begin: Wizard Form&ndash;&gt;

                        <v-row>

                        </v-row>

                    </div>
                </div>-->
            </div>

        </div>
        <manage-category ref="manageCategory" @refresh_menu="getMenu"></manage-category>
        </div>
      </div>
    </v-app>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import CreateAndUpdate from "./CreateAndUpdate";
    import draggable from 'vuedraggable'
    import {VueNestable, VueNestableHandle} from 'vue-nestable'
    import PageService from "@/core/services/page/PageService";
    import BlogService from "@/core/services/blog/BlogService";
    import ProgramService from "@/core/services/level/program/ProgramService";
    import MenuService from "@/core/services/Menu/MenuService";
    import MenuItemService from "@/core/services/Menu/MenuItemService";
    import ManageCategory from "./ManageCategory";

    const pageService = new PageService();
    const blogService = new BlogService();
    const programService = new ProgramService();
    const menuService = new MenuService();
    const menuItemService = new MenuItemService();
    export default {
        name: "Menu",
        display: "Table",
        order: 8,
        components: {
            ManageCategory,
        },
        data() {
            return {

                menus: [],
                addMenudialog: false,
                dragging: false,
                search: {},

            };
        },
        mounted() {
            this.getMenu();

        },
        methods: {
            getMenu() {
                menuService
                    .paginate(this.search)
                    .then(response => {
                        this.menus = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            editMenu(menu) {
                this.edit = true;
                this.menu = menu;
                this.$refs['manageCategory'].showModal(this.menu);
            },


            manageCategory() {
                this.$refs['manageCategory'].showModal();
            },


            changeType(type) {
                this.newMenu.menu_type = type;
                this.newMenu.title = '';
            },
            addMenuForm() {
                this.selectedMenu = null;
            },
            opoenDialog() {
                this.addMenudialog = true;
            },
            closeDialog() {
                this.addMenudialog = false;
            },

            deleteMenu(id) {
                this.$confirm({
                    message: `Are you sure you want to delete this item?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            menuService
                                .delete(id)
                                .then(response => {
                                    this.getMenu();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },
            // sort() {
            //     videoGallery.sort(this.videos).then((res) => {
            //         this.$snotify.success("Sorted");
            //         this.getVideosGallery()
            //     }).catch(err => {
            //         this.$snotify.success("Sorted Error");
            //     })
            // },

        }
    };
</script>
<style scoped>
    .buttons {
        margin-top: 35px;
    }
</style>

<style lang="scss" scope>
    /*
    * Style for nestable
    */


    .nestable {
        position: relative;
    }

    .nestable-rtl {
        direction: rtl;
    }

    .nestable .nestable-list {
        margin: 0;
        padding: 0 0 0 40px;
        list-style-type: none;
    }

    .nestable-rtl .nestable-list {
        padding: 0 40px 0 0;
    }

    .nestable > .nestable-list {
        padding: 0;
    }

    .nestable-item,
    .nestable-item-copy {
        margin: 10px 0 0;
    }

    .nestable-item:first-child,
    .nestable-item-copy:first-child {
        margin-top: 0;
    }

    .nestable-item .nestable-list,
    .nestable-item-copy .nestable-list {
        margin-top: 10px;
    }

    .nestable-item {
        position: relative;
    }

    .nestable-item.is-dragging .nestable-list {
        pointer-events: none;
    }

    .nestable-item.is-dragging * {
        opacity: 0;
        filter: alpha(opacity=0);
    }

    .nestable-item.is-dragging:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(106, 127, 233, 0.274);
        border: 1px dashed rgb(73, 100, 241);
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }

    .nestable-drag-layer {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        pointer-events: none;
    }

    .nestable-rtl .nestable-drag-layer {
        left: auto;
        right: 0;
    }

    .nestable-drag-layer > .nestable-list {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        background-color: rgba(106, 127, 233, 0.274);
    }

    .nestable-rtl .nestable-drag-layer > .nestable-list {
        padding: 0;
    }

    .nestable [draggable="true"] {
        cursor: move;
    }

    .nestable-handle {
        display: inline;
    }

    .nestable-item .btn-group {
        float: right;
    }

    .nestable-item .nestable-item-content,
    .kt-portlet__body ol.nestable-list .nestable-item-content {
        color: black;
        font-weight: bold;
        padding: 1rem 2rem;
        border-radius: 5px;
        background: #eceff0;
    }

    .nestable-handle {
        margin-right: 10px;
        cursor: grabbing !important;
    }

    .nestable-handle span {
        font-weight: bold !important;
    }
</style>
